import React from 'react'
import {convertEndDate, getEndOfDay, getStartOfDay, TEXT, urlCreator} from '../Common.js'

class Item extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            imageLoaded: false
        }
    }

    addSimpleCampaigns(){
        let campaignRenderList = []
        this.props.item.benefits.sort(function(a, b) {
            return parseInt(a.order) - parseInt(b.order)
        })
        this.props.item.benefits.forEach(function (el, i) {
            console.log(el.fromdate, el.todate)
            if(getStartOfDay() >= new Date(el.fromdate).getTime() && getEndOfDay() <= convertEndDate(el.todate)) {
                if((el.title !== ' ' && el.title !== '') || (el.benefit !== ' ' && el.benefit !== '')) {
                    campaignRenderList.push(<p key={i}>
                        {el.type !== 'TABLERESERVATION' ? <span><i className="fas fa-tag float-left"></i></span> : null}
                            <span className="cs-offer-description">
                                {el.title !== ' ' && el.title !== '' ? el.title : null}
                                {el.benefit !== ' ' && el.benefit !== '' ? el.benefit : null}
                            </span>
                        </p>
                    )
                }
            }
        })
        return campaignRenderList
    }

    handleImageLoaded =()=>{
        this.setState({imageLoaded: true})
    }

    render(){
        const item = this.props.item
        const singleUrl = "/edut/"+ urlCreator(item.title)+"/"+item.id
        return(
            <div className="col-xs-12 col-md-6 col-lg-4">
                <div className="cs-box-offer">
                    <a
                        href={singleUrl}
                        className={"cs-img-box" + (!this.state.imageLoaded ? " esd-loading" : "")}
                        title={"Katso " + this.props.item.title + " edut ja alennukset"}
                    >
                        <img className="img-fluid" src={this.props.item.image.replace('.jpg', '-tn.jpg')} onLoad={this.handleImageLoaded} alt={this.props.item.title + " ilmoitus"} />
                    </a>
                    <h2>{this.props.item.title}</h2>
                    <section>{this.addSimpleCampaigns()}</section>
                    <a
                        href={singleUrl}
                        className="btn btn-primary btn-link"
                        title={"Katso " + this.props.item.title + " edut ja alennukset"}
                    >
                    {TEXT.fi.more}
                    </a>
                </div>
            </div>
        )
    }
}

export default Item